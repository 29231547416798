import React, { Suspense } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import routes from '@common/config/routers';
import LayoutWraper from '@components/layout/LayoutWraper';
import { Skeleton } from 'antd';
import Login from '@pages/login';

function App() {
  const generateRoutes = () => {
    let result = null;
    result = routes.map((route, index) => {
      return route?.isMobile ? (
        <Route key={index} path={route.path} element={<div>mobile</div>} />
      ) : (
        <Route
          key={index}
          path={route.path}
          element={<LayoutWraper>{route.element}</LayoutWraper>}
        />
      );
    });
    return result;
  };

  return (
    <Suspense fallback={<Skeleton />}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          {generateRoutes()}
          <Route path="/*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
