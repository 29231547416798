import { useMutation, useQuery } from 'react-query';
import authApi from '@services/apis/auth';

export const usePostLogin = () => {
  return useMutation((params) => authApi.login(params));
};

export const usePostResetPwd = () => {
  return useMutation((params) => authApi.resetPwd(params));
};

export const usePostChangePwd = () => {
  return useMutation((params) => authApi.changePwd(params));
};

export const useGetProfile = () =>
  useQuery('get_profile', () => authApi.getMe());

export const usePostInitPwd = () => {
  return useMutation((params) => authApi.initPwd(params));
};
