import React from 'react';
import { Link } from 'react-router-dom';
import { IconDropDown, IconProfile } from '@assets/icons';
import { PATH_URL_PC } from '@common/config/pathURL';
import cache, { sessionCache } from '@common/utils/cache';
import { MyAlert } from '@components/common';
import { Dropdown, Layout, Row } from 'antd';
import PropTypes from 'prop-types';
import Breadcrumb from './Breadcrumb';

function Header({ profile }) {
  const handleLogout = () => {
    if (cache.get('token')) {
      cache.remove('token');
      cache.remove('refreshToken');
    } else {
      sessionCache.remove('token');
      sessionCache.remove('refreshToken');
    }
  };
  const menuItems = [
    {
      label: (
        <Link onClick={handleLogout} to={PATH_URL_PC.LOGIN}>
          ログアウト
        </Link>
      ),
      key: '2',
    },
  ];

  return (
    <Layout.Header className="layout-wraper-header">
      <Row justify="center" className="pos-abs pos-center left-50 top-50">
        <MyAlert />
      </Row>
      <Row justify="space-between" align="middle" className="header-content">
        <Breadcrumb />
        <Row align="middle" className="group-profile">
          <div className="avatar" />
          <Dropdown
            overlayClassName="dropdown-profile"
            menu={{ items: menuItems }}
            trigger={['click']}
            placement="bottomRight"
          >
            <Row align="middle" className="fw-700">
              <IconProfile className="mr-8" />
              {profile?.userNm}
              <IconDropDown className="ml-20" />
            </Row>
          </Dropdown>
        </Row>
      </Row>
    </Layout.Header>
  );
}

export default Header;

Header.propTypes = {
  profile: PropTypes.object,
};
