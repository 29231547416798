import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconSiderCollapse, IconSiderLogo } from '@assets/icons';
import { PATH_URL_PC } from '@common/config/pathURL';
import { PORTAL_PER } from '@common/constant/auth';
import { useLayout } from '@common/contexts/Layout';
import useWindowSize from '@common/hooks/useWindowSize';
import { Layout, Menu, Row } from 'antd';
import PropTypes from 'prop-types';

function SideBar({ profile }) {
  const [items, setItems] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { collapsed, setCollapsed } = useLayout();
  const { height } = useWindowSize();

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  useEffect(() => {
    if (!profile) return;
    const { authRoleDiv } = profile;
    const tmp = [
      getItem('ホーム', PATH_URL_PC.HOME),
      PORTAL_PER.includes(authRoleDiv) &&
        getItem('ご注文照会', PATH_URL_PC.ORDER_LIST),
    ];
    setItems(tmp);
  }, [profile]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onClickMenu = (e) => {
    navigate(e.key);
  };
  const pathNameSnippets =
    location.pathname === '/'
      ? location.pathname
      : `/${location.pathname
          .split('/')
          .filter((i) => i)
          .at(0)}`;

  return (
    <Layout.Sider
      collapsed={collapsed}
      className="layout-wraper-sider"
      style={{ height }}
      width={240}
    >
      <Row justify="center" align="middle" className="sider-logo">
        <IconSiderLogo className="icon-logo" />
        <IconSiderCollapse
          className="icon-collapsed"
          onClick={toggleCollapsed}
        />
      </Row>
      <Menu
        onClick={onClickMenu}
        mode="inline"
        items={items}
        className="sider-menu"
        selectedKeys={[pathNameSnippets]}
      />
    </Layout.Sider>
  );
}

export default SideBar;

SideBar.propTypes = {
  profile: PropTypes.object,
};
