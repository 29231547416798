export const PATH_URL_PC = {
  HOME: '/',
  LOGIN: '/login',
  ACCOUNT: '/account',
  ORDER_LIST: '/orders',
  ORDER_DETAIL: '/orders/:idOrder',
};

export const PATH_URL_MOBILE = {
  LOGIN: '/mobile/login',
  DASHBOARD: '/mobile/dashboard',
};
