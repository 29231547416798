import { useQueries, useQuery } from 'react-query';
import DIV_NAME from '@common/constant/masterData';
import masterDataApi from '@services/apis/masterData';

export const useGetDivs = () => {
  const arrValueDivs = Object.values(DIV_NAME);
  const queries = useQueries(
    arrValueDivs.map((valueDiv) => ({
      queryKey: valueDiv,
      queryFn: () => masterDataApi.getDiv(valueDiv),
      staleTime: Infinity,
    })),
  );
  const dataConverted =
    queries?.map((query) => {
      return query?.data?.map((e) => ({
        label: e.divValueNm,
        value: e.divValue,
      }));
    }) || [];

  const [
    rolesDiv,
    invoiceDeadlineDiv,
    paymentDeadlineDiv,
    workFormDiv,
    quantityUnitDiv,
    packingDiv,
    orderWayDiv,
    timeDiv,
    dowDiv,
    consolidationDiv,
    statusDiv,
    serviceStatusDiv,
    serviceYmdDiv,
    operatingDaySearchCategoryDiv,
    workKindsDiv,
    allocationStatusDiv,
    formDiv,
    formOutputDiv,
    closingMonthDiv,
    orderLinkage,
    workStatusDiv,
    baseType,
    pickDeliTypeDiv,
    pickDeliWayDiv,
    deadlineMonth,
    deadlineDay,
    authRoleDiv,
  ] = dataConverted;
  return {
    rolesDiv,
    invoiceDeadlineDiv,
    paymentDeadlineDiv,
    workFormDiv,
    quantityUnitDiv,
    packingDiv,
    orderWayDiv,
    timeDiv,
    dowDiv,
    consolidationDiv,
    statusDiv,
    serviceStatusDiv,
    serviceYmdDiv,
    operatingDaySearchCategoryDiv,
    workKindsDiv,
    allocationStatusDiv,
    formDiv,
    formOutputDiv,
    closingMonthDiv,
    orderLinkage,
    workStatusDiv,
    baseType,
    pickDeliTypeDiv,
    pickDeliWayDiv,
    deadlineMonth,
    deadlineDay,
    authRoleDiv,
  };
};

export const useGetCustomers = () => {
  const { data } = useQuery('cutomers', masterDataApi.getCustomers);
  const listCustomer = data?.data?.map((e) => ({
    label: e.customerNm,
    value: e.customerId,
  }));
  return listCustomer;
};

export const useGetRoutes = () => {
  const { data } = useQuery('routes', masterDataApi.getRoutes);
  const listRoute = data?.map((e) => ({
    label: e.routeNm,
    value: e.routeId,
    ...e,
  }));
  return listRoute;
};

export const useGetCompanies = () => {
  const { data } = useQuery('companies', masterDataApi.getCompanies);
  const listCompanies = data?.data?.map((e) => ({
    label: e.companyNm,
    value: e.companyId,
    ...e,
  }));
  return listCompanies;
};
