/* eslint-disable react/jsx-filename-extension */

/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { PATH_URL_PC } from './pathURL';

const Home = React.lazy(() => import('@pages/home/Home'));
const Login = React.lazy(() => import('@pages/login'));
const OrderList = React.lazy(() => import('@pages/orders/OrderList'));
const OrderDetail = React.lazy(() => import('@pages/orders/OrderDetail'));

const routes = [
  // route pc
  {
    path: PATH_URL_PC.HOME,
    element: <Home />,
  },
  {
    path: PATH_URL_PC.LOGIN,
    element: <Login />,
  },

  {
    path: PATH_URL_PC.ORDER_LIST,
    element: <OrderList />,
    breadcrumb: 'ご注文照会',
  },
  {
    path: PATH_URL_PC.ORDER_DETAIL,
    element: <OrderDetail />,
    breadcrumb: 'ご注文詳細',
  },

  // route mobile
];

export default routes;
